import React from 'react'
import { usePostRequest } from '../hooks/request'
import { BORROW_IMAGE_LIST } from '../urls'
import BorrowImageForm from './BorrowImageForm'

export default function BorrowImageCreate({ onCancel, clientId, borrows }) {
    const products = usePostRequest({ url: BORROW_IMAGE_LIST })

    async function onSubmit(data) {
        const { success } = await products.request({
            data,
        })

        if (success) {
            borrows.request()
            onCancel()
        }
    }

    return <BorrowImageForm clientId={clientId} loading={products.loading} onCancel={onCancel} onSubmit={onSubmit} />
}
