import React from 'react'
import { usePutRequest } from '../hooks/request'
import { CLIENT_DETAIL } from '../urls'
import ClientForm from './ClientForm'
import { useMessage } from '../hooks/message'

export default function ClientUpdate({
    onCancel,
    item,
    clients,
    check,

}) {
    const [showMessage] = useMessage()
    const client = usePutRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })
    async function onSubmit(data, action) {
        const {
            success,
            error,
        } = await client.request({ data })

        if (success) {
            onCancel()

            if (check) {
                check.request()
            }

            if (clients) {
                clients.request()
            }

            action.resetForm()
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered is-capitalized"><b>Изменить клиента</b></h1>
            <ClientForm
                loading={client.loading}
                initialValues={item}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>

    )
}
