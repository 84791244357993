import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { useLoad } from '../hooks/request'
import { CLIENT_TYPE_LIST } from '../urls'

export default function BorrowTabs() {
    const location = useLocation()

    const getQueryParam = (param) => new URLSearchParams(location.search).get(param)

    const clientType = getQueryParam('client_type')
    const type = useLoad({ url: CLIENT_TYPE_LIST })
    const typeItem = type.response ? type.response.results : []

    return (
        <div className="tabs">
            <ul>
                {typeItem.map((item) => (
                    <li
                        key={item.id}
                        className={cn({ 'is-active': clientType === String(item.id) })}
                    >
                        <NavLink to={`/borrow?client_type=${item.id}`}>{item.name}</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}
