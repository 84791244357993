import React from 'react'
import Button from './common/Button'
import { BORROW_IMAGE_DETAIL, domain } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { getDateTime } from '../utils/date'

export default function BorrowImageItem({ item, borrows }) {
    const productRemove = useDeleteRequest({ url: BORROW_IMAGE_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            borrows.request()
        }
    }

    return (
        <tr key={item.id}>
            <td className="is-size-6"><img width={200} height={200} src={domain + item.image} alt="asd" /></td>
            <td className="is-size-6">
                {getDateTime(item.createdAt)}
            </td>
            <td className="has-text-right">
                <Button
                    onClick={onDelete}
                    className="button is-danger mr-2 is-outlined is-small"
                    icon="trash" />

            </td>
        </tr>
    )
}
