import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { useLoad } from '../hooks/request'
import { CLIENT_TYPE_LIST } from '../urls'

export default function ClientForm({ onCancel, onSubmit, initialValues, loading }) {
    const type = useLoad({ url: CLIENT_TYPE_LIST })
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                name: '',
                phoneNumber: '',
                clientType: '',
                ...initialValues,
            }}>
            <Form>
                <Input
                    name="name"
                    label="Имя"
                    validate={required} />

                <Input
                    type="tel"
                    name="phoneNumber"
                    label="Номер телефона"
                    validate={required} />

                <Select
                    empty
                    name="clientType"
                    label="Тип клиента"
                    options={type.response ? type.response.results : []}
                    disabled={loading} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Закрыть"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
