import React, { useState } from 'react'
import { StyleSheet } from 'aphrodite'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useLoad } from '../hooks/request'
import { BORROW_IMAGE_LIST, CLIENT_DETAIL } from '../urls'
import Table from './common/Table'
import { useQueryParams } from '../hooks/queryString'
import Button from './common/Button'
import { useCustomModal } from '../hooks/customModal'
import Layout from './Layout'
import BorrowImageItem from './BorrowImageItem'
import BorrowImageCreate from './BorrowImageCreate'

export default function ClientBorrowImage() {
    const { clientId } = useParams()
    const { params } = useQueryParams()
    const [page, setPage] = useState(1)
    const { response: client } = useLoad({ url: CLIENT_DETAIL.replace('{id}', clientId) })
    const borrows = useLoad({
        url: BORROW_IMAGE_LIST,
        params: { page, ...params, client: clientId },
    }, [params, page])
    const borrowItem = borrows.response ? borrows.response.results : []

    const [showCreateModal, hideCreateModal] = useCustomModal(<BorrowImageCreate borrows={borrows} clientId={clientId}
        onCancel={() => hideCreateModal()} />, styles.modal)

    return (
        <Layout>
            <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Link className="button is-white is-link is-outlined" to="/borrow">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </Link>
                    <span className="is-size-4">
                        <b>
                            {client?.name} - {client?.phoneNumber}
                        </b>
                    </span>
                </hgroup>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button onClick={showCreateModal} icon="add-outline" text="Добавить"
                        className="is-link is-outlined" />
                </div>
            </header>

            <hr />

            <div>
                <Table
                    loading={borrows.loading}
                    items={borrowItem}
                    columns={{
                        price: 'Картина',
                        sactions: 'Дата',
                        actions: '',
                    }}
                    totalCount={borrows.response ? borrows.response.count : 0}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => (
                        <BorrowImageItem index={index} borrows={borrows}
                            clientId={clientId} key={item.id} item={item} />
                    )}
                />
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
})
