import React, { useState } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import { CLIENT_DETAIL, SEND_MESSAGE } from '../urls'
import { useDeleteRequest, useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat, timeAgo } from '../utils/date'
import { format } from '../utils/number'
import ClientUpdate from './ClientUpdate'
import { useMessage } from '../hooks/message'

export default function BorrowItem({ item, clients, onCheckboxChange, index }) {
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    const productRemove = useDeleteRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })
    const history = useHistory()

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            clients.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(<ClientUpdate clients={clients} item={item} onCancel={() => hideUpdateModal()} />)

    const currentDate = new Date()

    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked
        setIsChecked(newCheckedState)
        onCheckboxChange(item.id, newCheckedState)
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger is-size-6' : 'has-text-black is-size-6'}`} key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">
                <Link to={`/borrow/${item.id}`} className={cn('', `${item.sumAmount > 0 ? 'has-text-link' : 'has-text-link'}`)}>
                    {item.name}
                </Link>
            </td>
            <td className="is-size-6">{item.phoneNumber}</td>

            <td className="is-size-6">
                {item.sumAmount === 0 && item.usdAmount === 0 ? (
                    <p className="has-text-grey">Qarzdorlik yo'q</p>
                ) : (
                    <>
                        {item.sumAmount !== 0 && <p className={`${item.sumAmount < 0 ? 'has-text-danger' : 'has-text-success'}`}>{format(item.sumAmount)} Сум</p>}
                        {item.usdAmount !== 0 && <p className={`${item.usdAmount < 0 ? 'has-text-danger' : 'has-text-success'}`}>{format(item.usdAmount)} USD</p>}
                    </>
                )}
            </td>

            <td>
                <input className="checkbox" style={{ width: 25, height: 25 }} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            </td>
            <td className="">{item.lastSendDate ? timeAgo(item.lastSendDate) : ''}</td>
            <td className="has-text-right">
                <Button onClick={() => history.push(`/borrow/image/${item.id}`)}
                    className="mr-2 button is-primary is-outlined is-small"
                    icon="camera-outline" />
                <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger mr-2 is-outlined is-small"
                    icon="trash" />

                <Button loading={sendMessage.loading} onClick={onSend} className="button is-link is-outlined is-small" icon="paper-plane-outline" />
            </td>
        </tr>
    )
}
