import cn from 'classnames'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { StyleSheet, css } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import CheckCreate from '../../components/CheckCreate'
import Layout from '../../components/Layout'
import Search from '../../components/Search'
import Table from '../../components/common/Table'
import { useModal } from '../../hooks/modal'
import { useQueryParams } from '../../hooks/queryString'
import { useGetRequest, useLoad } from '../../hooks/request'
import empty from '../../static/empty-shop.png'
import { NOMENCLATURE_LIST, PRODUCT_EXPORT, domain } from '../../urls'
import PromotionColumn from './Column'
import PromotionCreate from './promotion-create'
import CustomersActionsBar from '../../components/CustomersActionsBar'

export default function Promotion() {
    const queryParams = useQueryParams()
    const [params, setParams] = useState({})

    const [page, setPage] = useState(1)
    const products = useLoad({ url: NOMENCLATURE_LIST, params: { page, ...queryParams, status: 'active', ...params } }, [queryParams, page, params])
    const productList = products.response ? products?.response.results : []

    const check = useLoad({ url: '/main/order' })
    const navigate = useHistory()
    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    const [showCreateModal, setShowCreateModal] = useModal(<PromotionCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        navigate.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="is-flex is-justify-content-space-between mb-3">
                <Search onSearch={onSearch} style={{ width: '40%' }} />
            </div>
            <CustomersActionsBar setSearchParams={setParams} />

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <Table
                        loading={products.loading}
                        items={productList}
                        columns={{
                            name: 'Наименование товара',
                            car: 'Название автомобиля',
                            brand: 'Название бренда',
                            manufacturer: 'Производитель',
                            quantity: 'Остаток   ',
                            price: 'Цена поступление',
                            priceTransfer: 'Оптом цена',
                            priceSale: 'Цена за единицу',
                            currency: 'Валюта',
                            category: 'Категория',
                            productLocation: 'Местоположение\n',
                            actions: 'Действие',

                        }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={50}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <PromotionColumn products={products} key={item.id} item={item} index={index} />}
                    />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
