import React from 'react'
import { usePostRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import { CLIENT_TYPE_LIST } from '../urls'
import ClientTypeForm from './ClientTypeForm'

export default function ClientTypeCreate({ onSuccess, onCancel }) {
    const brandCreate = usePostRequest({ url: CLIENT_TYPE_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandCreate.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить</b></h1>

            <ClientTypeForm
                loading={brandCreate.loading}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
