import React from 'react'
import { usePutRequest } from '../hooks/request'
import ClientTypeForm from './ClientTypeForm'
import { CLIENT_TYPE_DETAIL } from '../urls'

export default function ClientTypeUpdate({ onCancel, item, category }) {
    const categoryUpdate = usePutRequest({ url: CLIENT_TYPE_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await categoryUpdate.request({ data })
        if (success) {
            category.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить категории</b></h1>

            <ClientTypeForm
                onCancel={onCancel}
                initialValues={item}
                onSubmit={onSubmit} />
        </div>
    )
}
