import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as domain from 'domain'
import Button from './common/Button'

export default function BorrowImageForm({ onCancel, onSubmit, initialValues, loading, clientId }) {
    const [file, setFile] = useState(null)

    async function onSuccess() {
        const newData = new FormData()
        if (file) {
            newData.append('image', file, file.name)
        }
        newData.append('client', clientId)
        onSubmit(newData)
    }

    return (
        <Formik onSubmit={onSuccess} initialValues={{
            image: '',
            ...initialValues,
        }}>
            {({ setFieldValue }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            <p className="level-left">Загрузить изображение</p>
                            <input
                                onChange={(e) => {
                                    setFieldValue('image', e.target.value)
                                    setFile(e.target.files[0])
                                }}
                                disabled={loading}
                                name="image"
                                type="file"
                            />
                            {initialValues ? (domain + initialValues.image).split('/').pop() : 'Файл не загружен'}
                        </div>
                    </div>

                    <Button loading={loading} text="Сохранить" type="submit" icon="ion-md-checkmark"
                        className="is-success" /> &nbsp;
                    <Button onClick={onCancel} icon="ion-md-close" text="Отмена" className="is-danger" />
                </Form>
            )}

        </Formik>
    )
}
